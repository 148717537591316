<template>
	<form @submit.prevent="saveItem">
		<b-col cols="12" class="p-0">
			<template v-if="isLoading">
				<b-spinner
					class="mb-1"
					variant="primary"
				/>
				<br>
					Loading...
			</template>
			<template v-else>
				<b-card>
					<div class="form-check form-check-inline mb-1" style="gap:10px;">
						<h3 class="mb-0">Biaya Platform</h3>
						<div class="custom-control custom-checkbox">
							<input 
								name="platform" 
								class="custom-control-input" 
								type="radio" 
								id="nominalPlatform" 
								value="nominal" 
								v-model="platformFee.fee_type"
							>
							<label 
								class="custom-control-label" 
								for="nominalPlatform" 
							>
							</label>
						</div>
					</div>
					<p class="mb-2">Biaya platform dihitung dari total biaya pajak yang dibayar</p>
					<b-row>
						<b-col cols="12" md="6" v-for="nominals , index in platformNominal" :key="index">
							<h5>{{ nominals.target_type == 'personal' ? 'Pribadi' : 'Perusahaan'  }}</h5>
							<div class="form-group">
								<label>{{ nominals.target_keyword == 'car' ? 'Mobil' : 'Motor' }}</label>
								<div class="d-flex flex-column" style="gap:12px;">
									<div class="form-check form-check-inline" style="gap:10px;">
										<div class="input-group">
											<div class="input-group-prepend">
												<span class="input-group-text">Rp</span>
											</div>
											<input 
												type="number" 
												class="form-control"
												v-model="nominals.amount"
												:disabled="nominals.amount_type != 'nominal' || platformFee.fee_type != 'nominal'"
											>
										</div>
										<div class="custom-control custom-checkbox">
											<input 
												:name="'nominal' + index " 
												class="custom-control-input" 
												type="radio" 
												:id="'radioNominal' + index" 
												value="nominal" 
												v-model="nominals.amount_type"
												:disabled="platformFee.fee_type != 'nominal'"
											>
											<label 
												class="custom-control-label" 
												:for="'radioNominal' + index" 
											>
											</label>
										</div>
									</div>
									<div class="form-check form-check-inline" style="gap:10px;">
										<div class="input-group">
											<div class="input-group-prepend">
												<span class="input-group-text">%</span>
											</div>
											<input 
												type="number" 
												class="form-control"
												v-model="nominals.amount_percent"
												:disabled="nominals.amount_type != 'percent' || platformFee.fee_type != 'nominal'"
											>
										</div>
										<div class="custom-control custom-checkbox">
											<input 
												:name="'percent' + index " 
												class="custom-control-input" 
												type="radio" 
												:id="'radioPercent' + index" 
												value="percent" 
												v-model="nominals.amount_type"
												:disabled="platformFee.fee_type != 'nominal'"
											>
											<label 
												class="custom-control-label" 
												:for="'radioPercent' + index" 
											>
											</label>
										</div>
									</div>
								</div>
								<small
									v-for="(validation, index) in validations['items.' + index + '.amount']"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</div>
						</b-col>
					</b-row>
				</b-card>
				<b-card>
					<div class="form-check form-check-inline mb-1" style="gap:10px;">
						<h3 class="mb-0">Pajak Dibayar</h3>
						<div class="custom-control custom-checkbox">
							<input 
								name="platform" 
								class="custom-control-input" 
								type="radio" 
								id="rangePlatform" 
								value="range" 
								v-model="platformFee.fee_type"
							>
							<label 
								class="custom-control-label" 
								for="rangePlatform" 
							>
							</label>
						</div>
					</div>
					<b-row>
						<b-col cols="12" v-for="ranges , index in platformRange" :key="index">
							<div class="form-group">
								<label>Range {{ index + 1 }}</label>
								<div class="d-flex justify-content-between align-items-center w-75">
									<div class="input-group">
										<div class="input-group-prepend">
											<span class="input-group-text">Minimal</span>
										</div>
										<input 
											type="number" 
											class="form-control"
											v-model="ranges.minimum_amount"
											:disabled="platformFee.fee_type != 'range'"
										>
									</div>
									<small
										v-for="(validation, index) in validations['items.' + index + '.minimum_amount']"
										:key="`errorName${index}`"
										class="text-danger"
									>{{ validation }}</small>
									<div class="px-2">
										-
									</div>
									<div class="input-group">
										<div class="input-group-prepend">
											<span class="input-group-text">Maximal</span>
										</div>
										<input 
											type="number" 
											class="form-control"
											v-model="ranges.maximum_amount"
											:disabled="platformFee.fee_type != 'range'"
										>
									</div>
									<small
										v-for="(validation, index) in validations['items.' + index + '.maximum_amount']"
										:key="`errorName${index}`"
										class="text-danger"
									>{{ validation }}</small>
								</div>
							</div>
							<div class="form-group w-50">
								<div class="d-flex flex-column" style="gap:12px;">
									<div class="form-check form-check-inline" style="gap:10px;">
										<div class="input-group">
											<div class="input-group-prepend">
												<span class="input-group-text">Rp</span>
											</div>
											<input 
												type="number" 
												class="form-control"
												v-model="ranges.amount"
												:disabled="ranges.amount_type != 'nominal' || platformFee.fee_type != 'range'"
											>
										</div>
										<div class="custom-control custom-checkbox">
											<input 
												:name="'nominalRange' + index " 
												class="custom-control-input" 
												type="radio" 
												:id="'radioNominalRange' + index" 
												value="nominal" 
												v-model="ranges.amount_type"
												:disabled="platformFee.fee_type != 'range'"
											>
											<label 
												class="custom-control-label" 
												:for="'radioNominalRange' + index" 
											>
											</label>
										</div>
									</div>
									<div class="form-check form-check-inline" style="gap:10px;">
										<div class="input-group">
											<div class="input-group-prepend">
												<span class="input-group-text">%</span>
											</div>
											<input 
												type="number" 
												class="form-control"
												v-model="ranges.amount_percent"
												:disabled="ranges.amount_type != 'percent' || platformFee.fee_type != 'range'"
											>
										</div>
										<div class="custom-control custom-checkbox">
											<input 
												:name="'percentRange' + index " 
												class="custom-control-input" 
												type="radio" 
												:id="'radioPercentRange' + index" 
												value="percent" 
												v-model="ranges.amount_type"
												:disabled="platformFee.fee_type != 'range'"
											>
											<label 
												class="custom-control-label" 
												:for="'radioPercentRange' + index" 
											>
											</label>
										</div>
									</div>
								</div>
								<small
									v-for="(validation, index) in validations['items.' + index + '.amount']"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</div>
							<div class="w-100 text-right">
								<b-button
									variant="danger"
									class="btn waves-effect waves-float waves-light btn-danger"
									@click="removeRange(index)" v-show="index > 0"
									:disabled="platformFee.fee_type != 'range'"
								>
									Delete Range
								</b-button>
							</div>
						</b-col>
						<b-col cols="12">
							<b-button
								variant="primary"
								class="btn waves-effect waves-float waves-light btn-primary mt-1"
								@click="addRange"
								:disabled="platformFee.fee_type != 'range'"
							>
								Add Range
							</b-button>
						</b-col>
					</b-row>
				</b-card>
			</template>
		</b-col>
		<b-col cols="12" class="p-0 mt-2">
		<template v-if="isLoading">
			<button
				variant="primary"
				class="btn waves-effect waves-float waves-light btn-primary"
				disabled
			>
				<b-spinner class="align-middle"></b-spinner>
				<strong>Loading...</strong>
			</button>
		</template>
		<template v-else>
			<button
				variant="primary"
				class="btn waves-effect waves-float waves-light btn-primary"
			>
				Save
			</button>
		</template>
		</b-col>
	</form>
</template>

<script>
import 
	{ 
		BCard, 
		BCardText, 
		BForm, 
		BButton ,
		BCol,
		BRow,
		BSpinner,
		BFormFile,
	} from 'bootstrap-vue'
import { successNotification, errorNotification } from '@/auth/utils'
import { required } from '@validations'
import _ from 'lodash'

export default {
	components: {
		BCard,
		BCardText,
		BForm,
		BButton,
		BCol,
		BRow,
		BSpinner,
		BFormFile,
	},

	data() {
		return {
			required,
			platformFee: {
				fee_type: '',
			},
			formRange: [],
			paymentPlatform: {},
			platformNominal: [],
			platformRange: [],
			isLoading: false,
			validations: ''
		}
	},

	setup() {
		return {
			successNotification,
			errorNotification,
		}
	},

	methods: {
		addRange() {
        	this.platformRange.push({ 
				amount: 0,
				amount_percent: 0,
				amount_type: '',
				fee_type: 'range',
				minimum_amount: '',
				maximum_amount: '',
				target_keyword: null,
				target_type: null,
			});
		},
		removeRange(index) {
			this.platformRange.splice(index, 1);
		},
		
		getData() { 
			this.isLoading = true
			const queryParams = this.filter
			this.$http.get('admin/platform-fee/pkb-payment', {
				params: queryParams,
			}).then(response => {
				this.platformFee = response.data.data
				console.log(this.platformFee);
				this.platformFee.items.forEach((element, index) => {
					if (element.amount_type == 'percent') {
						element['amount_percent'] = element.amount
						element.amount = 0
					}
					this.paymentPlatform[element.fee_type] = this.platformFee.items.filter(item => item.fee_type == element.fee_type)
					this.platformNominal = this.paymentPlatform.nominal
					this.platformRange = this.paymentPlatform.range
				});
					this.isLoading = false
				}).catch(error => {
					this.isLoading = false
				})
		},

		async saveItem() {
			this.isLoading = true
			const payload = new FormData()
			payload.append("fee_type", this.platformFee.fee_type)
			if (this.platformNominal !== null) {
				await this.platformNominal.forEach((items, index) => {
					Object.keys(items).forEach(key => {
						if (key == 'amount') {
							if (items.amount_type == 'percent') {
								payload.append('items' + '['+ index + '][' + key + ']', items['amount_percent']);
							} else {
								payload.append('items' + '['+ index + '][' + key + ']', items[key]);
							}
						} else {
							payload.append('items' + '['+ index + '][' + key + ']', items[key]);
						}			
					});
				});
			}
			if (this.platformRange !== null) {
				await this.platformRange.forEach((items, index) => {
					let lastIndexNominal = this.platformNominal.length

					Object.keys(items).forEach(key => {
						if (key == 'amount') {
							if (items.amount_type == 'percent') {
								payload.append('items' + '['+ (lastIndexNominal + index) + '][' + key + ']', items['amount_percent']);
							} else {
								payload.append('items' + '['+ (lastIndexNominal + index) + '][' + key + ']', items[key]);
							}
						} else {
							payload.append('items' + '['+ (lastIndexNominal + index) + '][' + key + ']', items[key]);
						}			
					});
				});
			}
			let uuid = this.platformFee.uuid
			this.$http.post('admin/platform-fee/' + uuid, payload , {
				headers: { 'Content-Type': 'multipart/form-data' },
			}).then(response => {
				successNotification(this, 'Success', 'Biaya platform berhasil diedit!')
				this.isLoading = false
				this.validations = ''
			}).catch(error => {
				this.validations = error.response.data.meta.validations
				if (error.response.data.meta.messages) {
					errorNotification(this, 'Oops!', error.response.data.meta.messages) 
					this.isLoading = false
				}
				// if (error.response.data.meta.validations) {
				// 	this.validations = error.response.data.meta.validations
				// 	errorNotification(this, 'Oops!', 'Input ada yang tidak terisi')
				// }
				this.isLoading = false
			})
		}

	},

	created() {
		this.getData()
	}
}
</script>

<style lang="scss">
.images__hint {
	font-size: 10px;
	margin-bottom: 0;
}
</style>>
	
</style>
